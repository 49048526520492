import {
  PushNotificationKeys,
  listUserNotifications,
  markNotificationsAsRead,
  registerForPushNotificationsAsync,
  unregisterForPushNotificationsAsync,
} from '@/api/repositories/pushNotificationRepository';
import {PaginatedPayload} from '@/api/repositories/types/commonTypes';
import {PushNotificationDto} from '@/api/repositories/types/pushNotificationTypes';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useSession} from 'next-auth/react';
import {useMemo} from 'react';

export const PUSH_NOTIFICATION_LOCAL_STORAGE_KEYS = {
  TOKEN: 'push_notification_token',
  SYNCED: 'push_notification_synced',
} as const;

export const usePushNotifications = (
  opts: {fetchEnabled?: boolean} = {fetchEnabled: false}
) => {
  const queryClient = useQueryClient();
  const {data} = useSession();

  const registerMutation = useMutation({
    mutationFn: registerForPushNotificationsAsync,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [PushNotificationKeys.LIST_USER_NOTIFICATIONS],
      });

      try {
        localStorage.setItem(
          PUSH_NOTIFICATION_LOCAL_STORAGE_KEYS.SYNCED,
          'true'
        );
        if (variables.token) {
          localStorage.setItem(
            PUSH_NOTIFICATION_LOCAL_STORAGE_KEYS.TOKEN,
            variables.token
          );
        }
      } catch (error) {
        console.error(
          'ERROR: [usePushNotifications] -> registerForPushNotificationsAsync',
          error
        );
      }
    },
    onError: error => {
      console.error(
        'ERROR: [usePushNotifications] -> registerForPushNotificationsAsync',
        error
      );

      try {
        localStorage.setItem(
          PUSH_NOTIFICATION_LOCAL_STORAGE_KEYS.SYNCED,
          'false'
        );
      } catch (error) {
        console.error(
          'ERROR: [usePushNotifications] -> registerForPushNotificationsAsync',
          error
        );
      }
    },
  });

  const unregisterMutation = useMutation({
    mutationFn: () => {
      const token = localStorage.getItem(
        PUSH_NOTIFICATION_LOCAL_STORAGE_KEYS.TOKEN
      );
      if (token) {
        return unregisterForPushNotificationsAsync({token});
      }
      return Promise.resolve();
    },
    onSuccess: () => {
      try {
        localStorage.setItem(
          PUSH_NOTIFICATION_LOCAL_STORAGE_KEYS.SYNCED,
          'true'
        );

        localStorage.removeItem(PUSH_NOTIFICATION_LOCAL_STORAGE_KEYS.TOKEN);
      } catch (error) {
        console.error(
          'ERROR: [usePushNotifications] -> registerForPushNotificationsAsync',
          error
        );
      }
    },
    onError: error => {
      try {
        localStorage.setItem(
          PUSH_NOTIFICATION_LOCAL_STORAGE_KEYS.SYNCED,
          'false'
        );
      } catch (error) {
        console.error(
          'ERROR: [usePushNotifications] -> registerForPushNotificationsAsync',
          error
        );
      }

      console.error(
        'ERROR: [usePushNotifications] -> unregisterForPushNotificationsAsync',
        error
      );
    },
  });

  const markNotificationsAsReadMutation = useMutation({
    mutationFn: markNotificationsAsRead,
    onMutate: async () => {
      await queryClient.cancelQueries([
        PushNotificationKeys.LIST_USER_NOTIFICATIONS,
      ]);

      const previousNotifications = queryClient.getQueryData([
        PushNotificationKeys.LIST_USER_NOTIFICATIONS,
      ]);

      queryClient.setQueryData<PushNotificationDto[]>(
        [PushNotificationKeys.LIST_USER_NOTIFICATIONS],
        old => {
          if (old && Array.isArray(old)) {
            return old.map(notification => {
              const mutated: PushNotificationDto = {
                ...notification,
                isOpen: true,
              };
              return mutated;
            });
          }
          return [];
        }
      );

      return {previousNotifications};
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        [PushNotificationKeys.LIST_USER_NOTIFICATIONS],
        context?.previousNotifications
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        PushNotificationKeys.LIST_USER_NOTIFICATIONS,
      ]);
    },
  });

  const payload: PaginatedPayload = {
    maxResultCount: 20,
    skipCount: 0,
  };

  const userNotificationQuery = useQuery({
    queryKey: [PushNotificationKeys.LIST_USER_NOTIFICATIONS],
    queryFn: async () => {
      // add artificial delay of 1s in order for exios token to be attached on initial load. Maybe there is another, better way to do this?
      await new Promise(resolve => setTimeout(resolve, 1000));
      return listUserNotifications(payload);
    },
    staleTime: 60000, // 1 minute
    enabled: data?.user?.email ? opts.fetchEnabled : false,
  });

  const numberOfUnreadNotifications = useMemo(() => {
    const notifications = userNotificationQuery.data ?? [];
    if (
      !notifications ||
      !notifications.length ||
      !Array.isArray(notifications)
    ) {
      return 0;
    }
    return notifications.filter(notification => !notification.isOpen).length;
  }, [userNotificationQuery.data]);

  return {
    registerForPushNotifications: registerMutation.mutate,
    unregisterForPushNotifications: unregisterMutation.mutate,
    userNotificationQuery,
    numberOfUnreadNotifications,
    markNotificationsAsRead: markNotificationsAsReadMutation,
  };
};
