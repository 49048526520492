'use client';

import React, { useState } from 'react';
import { Link, usePathname, useRouter } from '@/utils/navigation';
import { useParams, useSearchParams } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { useLocale, useTranslations } from 'next-intl';

import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { AppBar, Divider, ListItemButton, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import LanguageIcon from '@mui/icons-material/Language';
import HistoryIcon from '@mui/icons-material/History';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import { getLocalizedText } from '../../helpers/helpers';
// API & React Query
import {
  ContentPublicQueryKeys,
  getContentPublicCategories,
} from '@/api/repositories/contentPublicRepository';
import { useQuery } from '@tanstack/react-query';
// Store
import { useCurrencySlice } from '@/store/store';

// Import CurrencySwitcher component
import useCurrencySwitcher from '../../hooks/useCurrencySwitcher';
import { useClientAuthMethods } from '@/lib/useClientAuthMethods';
import { useLanguageOptions } from '@/providers/LanguageOptionsProvider';
import { availableLocales } from '@/availableLocales';

const MobileHeaderMenu = () => {
  const t = useTranslations('WebNavLinks');
  const locale = useLocale();
  const pathname = usePathname();
  const router = useRouter();
  const params = useParams();
  const searchParams = useSearchParams();
  const { data: session } = useSession();
  const { signOut } = useClientAuthMethods();
  const { data: languageOptions } = useLanguageOptions();

  // * React Query
  const { data: discoverMenuItems } = useQuery({
    queryKey: [ContentPublicQueryKeys.CONTENT_PUBLIC_CATEGORIES],
    queryFn: () => getContentPublicCategories(),
  });

  const { handleCurrencyChange } = useCurrencySwitcher();

  //* Store
  const [currency] = useCurrencySlice((state) => [state.currency]);

  const [currentMenu, setCurrentMenu] = useState<string>('main');

  const handleLanguageChange = (lan: typeof locale) => {
    const queryString = searchParams.toString();
    const fullPathname = queryString ? `${pathname}?${queryString}` : pathname;
    // @ts-expect-error -- TypeScript will validate that only known `params`
    // are used in combination with a given `pathname`. Since the two will
    // always match for the current route, we can skip runtime checks.
    router.replace({ pathname: fullPathname, params }, { locale: lan });
  };

  const displayLanguage = (lan: typeof locale) => {
    return languageOptions?.find((option) => option.code === lan)?.name || 'English';
  };

  const renderNavLinkWithArrow = (label: string, targetMenu: string) => (
    <ListItemButton
      onClick={() => {
        setCurrentMenu(targetMenu);
      }}
    >
      <ListItemText primary={label} />
      <Box sx={{ flex: '1 1 auto' }} />
      <ChevronRightIcon />
    </ListItemButton>
  );

  const renderNavLinkMenuTitle = (label: string, targetMenu: string) => (
    <ListItemButton
      onClick={() => {
        setCurrentMenu(targetMenu);
      }}
    >
      <ArrowBackIcon />
      <ListItemText sx={{ pl: '16px' }} primary={label} />
    </ListItemButton>
  );

  const renderNavLinkWithIcons = (
    mainIcon: React.ReactNode,
    label: string,
    targetMenu: string,
    value?: string,
    href?: string
  ) => (
    <ListItemButton
      onClick={() => {
        setCurrentMenu(targetMenu);
      }}
    >
      {mainIcon}

      <Link href={targetMenu === 'main' && href !== undefined ? href : ''}>
        <ListItemText sx={{ pl: '16px' }} primary={label} />
      </Link>

      <Box sx={{ flex: '1 1 auto' }} />
      {targetMenu !== 'main' && (
        <>
          <ListItemText
            style={{
              paddingRight: '10px',
              fontSize: '12px',
              textAlign: 'right',
              color: 'grey',
            }}
            primary={value}
          />
          <ChevronRightIcon />
        </>
      )}
    </ListItemButton>
  );

  const renderNavLink = (href: string, label: string, targetMenu: string, action?: () => void) => (
    <Box component={action ? Box : Link} {...(!action && { href })}>
      <ListItemButton
        onClick={() => {
          targetMenu !== 'main' && setCurrentMenu(targetMenu);
          action !== undefined && action();
        }}
      >
        <ListItemText primary={label} />
      </ListItemButton>
    </Box>
  );

  const RenderMainMenu = () => (
    <>
      <List>
        {renderNavLink('/', t('Home'), 'main')}
        {renderNavLinkWithArrow(t('Company'), 'company')}
        {renderNavLinkWithArrow(t('Discover'), 'discover')}
        {renderNavLink('/offers/1', t('OffersAndNews'), 'main')}
        {/* {renderNavLink('/contact', t('Contact'), 'main')} */}
      </List>
      <Divider />
      <List
        sx={{
          pt: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        {renderNavLinkWithIcons(
          <CurrencyExchangeIcon />,
          t('Currency'),
          'currency',
          currency.label
        )}
        {renderNavLinkWithIcons(
          <LanguageIcon />,
          t('Language'),
          'language',
          displayLanguage(locale)
        )}
        {/* Include CurrencySwitcher component */}
        {/* <CurrencySwitcher /> */}
      </List>
    </>
  );

  const RenderCompanySubMenu = () => (
    <>
      <List>{renderNavLinkMenuTitle('Company', 'main')}</List>
      <Divider />
      <List>
        {renderNavLink('/about-us', t('AboutUs'), 'company')}
        {renderNavLink('/safety-and-quality', t('SecurityAndQuality'), 'company')}
        {renderNavLink('/faq', t('FAQ'), 'company')}
      </List>
    </>
  );

  const RenderCurrencySubMenu = () => (
    <>
      <List>{renderNavLinkMenuTitle(t('Currency'), 'main')}</List>
      <Divider />
      <List>
        {renderNavLink('', t('Euro'), 'currency', () => {
          handleCurrencyChange('EUR');
          setCurrentMenu('main');
        })}
        {renderNavLink('', t('BosnianMark'), 'currency', () => {
          handleCurrencyChange('BAM');
          setCurrentMenu('main');
        })}
      </List>
    </>
  );

  const RenderLanguageSubMenu = () => (
    <>
      <List>{renderNavLinkMenuTitle(t('Language'), 'main')}</List>
      <Divider />
      <List>
        {languageOptions?.map((option) => {
          if (availableLocales.includes(option.code)) {
            return (
              <div key={option.code}>
                {renderNavLink('', option.name, 'language', () =>
                  handleLanguageChange(option.code)
                )}
              </div>
            );
          }
          return null;
        })}
      </List>
    </>
  );

  const RenderDiscoverSubMenu = () => (
    <>
      <List>{renderNavLinkMenuTitle('Discover', 'main')}</List>
      <Divider />
      <List>
        {discoverMenuItems?.map((item, index) => (
          <div key={index}>
            {renderNavLink(
              `/${getLocalizedText(locale, item.nameToLower)}?currency=${currency.value}`,
              getLocalizedText(locale, item.name),
              'discover'
            )}
          </div>
        ))}
      </List>
    </>
  );

  const RenderCurrentMenu = () => {
    switch (currentMenu) {
      case 'company':
        return <RenderCompanySubMenu />;
      case 'discover':
        return <RenderDiscoverSubMenu />;
      case 'currency':
        return <RenderCurrencySubMenu />;
      case 'language':
        return <RenderLanguageSubMenu />;
      default:
        return <RenderMainMenu />;
    }
  };

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: 'surface',
        color: 'onSurface',
        boxShadow: 'none',
        height: '100vh',
      }}
      elevation={0}
    >
      <RenderCurrentMenu />
    </AppBar>
  );
};

export default MobileHeaderMenu;
