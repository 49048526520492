import React, { useState, MouseEvent, useEffect } from 'react';
import { Link } from '@/utils/navigation';
import { useTranslations, useLocale } from 'next-intl';
import { useSession } from 'next-auth/react';
import { useParams, useSearchParams } from 'next/navigation';
import { useRouter, usePathname } from '@/utils/navigation';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import Logo from '@/sharedComponents/Logo';
import CurrencySwitcher from './CurrencySwitcher';
import LanguageSwitcher from '@/sharedComponents/language-switcher/language-switcher';
import Notifications from '../Notifications/Notifications';
import UserBox from '../UserBox';

import AppMobileHeaderMenu from './AppMobileHeaderMenu';

import { getLocalizedText } from '../../helpers/helpers';
// API & React Query
import {
  ContentPublicQueryKeys,
  getContentPublicCategories,
} from '@/api/repositories/contentPublicRepository';
import { useQuery } from '@tanstack/react-query';
// Store
import { useCurrencySlice } from '@/store/store';

//TODO: Fix on mobile screens opening menu bg color

const buttonStyle = {
  textTransform: 'none',
  color: 'inherit',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
};

interface HeaderProps {
  transparent?: boolean;
  props?: Props;
  noTrigger?: boolean;
  lightMode?: boolean;
}

const DropdownMenu: React.FC<{
  anchorEl: HTMLElement | null;
  onClose: () => void;
  menuItems: { label: string; href: string }[];
}> = ({ anchorEl, onClose, menuItems }) => (
  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
    {menuItems.map((item) => (
      <Link key={item.href} href={item.href} passHref>
        <MenuItem onClick={onClose}>{item.label}</MenuItem>
      </Link>
    ))}
  </Menu>
);

interface Props {
  window?: () => Window;
  children: React.ReactElement;
  noTrigger?: boolean;
  mobileMenuOpen?: boolean;
}

const ElevationScroll = (props: Props) => {
  const { children, window, noTrigger, mobileMenuOpen } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    sx: {
      background:
        isMobile && mobileMenuOpen
          ? theme.palette.primary.main
          : noTrigger
            ? theme.palette.primary.main
            : trigger
              ? theme.palette.primary.main
              : 'transparent',
    },
  });
};

const Header = ({
  transparent = false,
  noTrigger = true,
  lightMode = false,
  props,
}: HeaderProps) => {
  const t = useTranslations('WebNavLinks');
  const { data: session } = useSession();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  //* Router
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const params = useParams();

  //* Store
  const currency = useCurrencySlice((state) => state.currency);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const locale = useLocale();
  // * React Query
  const { data: discoverMenuItems } = useQuery({
    queryKey: [ContentPublicQueryKeys.CONTENT_PUBLIC_CATEGORIES],
    queryFn: () => getContentPublicCategories(),
  });

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const lowerCaseString = (string: string) => {
    return string.toLowerCase();
  };

  const [companyAnchorEl, setCompanyAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [discoverAnchorEl, setDiscoverAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const handleCompanyMenuOpen = (event: MouseEvent<HTMLElement>) =>
    setCompanyAnchorEl(event.currentTarget);
  const handleDiscoverMenuOpen = (event: MouseEvent<HTMLElement>) =>
    setDiscoverAnchorEl(event.currentTarget);
  const handleMenuClose = () => setCompanyAnchorEl(null);
  const handleDiscoveryClose = () => setDiscoverAnchorEl(null);

  const isCompanyMenuOpen = Boolean(companyAnchorEl);
  const isDiscoverMenuOpen = Boolean(discoverAnchorEl);

  const renderNavLink = (href: string, label: string) => (
    <Link href={href}>
      <Button sx={buttonStyle}>{label}</Button>
    </Link>
  );

  //* Use effect
  // Update the URL and reload the page when the currency changes

  useEffect(() => {
    if (searchParams.has('currency')) {
      // @ts-expect-error -- TypeScript will validate that only known `params`
      // are used in combination with a given `pathname`. Since the two will
      // always match for the current route, we can skip runtime checks.
      router.push({ pathname, params, query: { currency: currency.value } });
    }
    // Update the URL and reload the page when the currency changes
  }, [currency]);

  return (
    <ElevationScroll
      noTrigger={noTrigger}
      mobileMenuOpen={mobileMenuOpen}
      {...props}
    >
      <AppBar
        position="fixed"
        sx={{
          backgroundColor:
            isMobile && mobileMenuOpen
              ? 'tomato'
              : lightMode
                ? theme.palette.surface
                : noTrigger
                  ? theme.palette.primary.main
                  : transparent
                    ? 'transparent'
                    : theme.palette.primary.main,
        }}
        // sx={{
        //   backgroundColor:
        //     isMobile && mobileMenuOpen ? theme.palette.primary.main : 'lightMode',
        // }}
      >
        <Container maxWidth="xl" style={{ padding: 0 }}>
          <Toolbar>
            <Stack
              direction="row"
              // spacing={2}
              justifyContent="space-between"
              display="flex"
              alignItems="center"
              width="100%"
            >
              <Box sx={{ display: { xs: 'none', lg: 'flex', padding: 0 } }}>
                <Link href={'/'}>
                  <Logo />
                </Link>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: { xs: 'flex', md: 'none' },
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleMobileMenu}
                  >
                    {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                  <Link href={'/'}>
                    <Logo width={80} height={32} />
                  </Link>
                </Box>
                <Box
                  sx={{
                    flexGrow: 2,
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'center',
                    gap: '27px',
                  }}
                >
                  {renderNavLink('/', t('Home'))}
                  <Button
                    onClick={handleCompanyMenuOpen}
                    sx={buttonStyle}
                    endIcon={
                      isCompanyMenuOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    }
                  >
                    {t('Company')}
                  </Button>
                  <DropdownMenu
                    anchorEl={companyAnchorEl}
                    onClose={handleMenuClose}
                    menuItems={[
                      { label: t('AboutUs'), href: '/about-us' },
                      {
                        label: t('SecurityAndQuality'),
                        href: '/safety-and-quality',
                      },
                      { label: t('FAQ'), href: '/faq' },
                    ]}
                  />
                  <Button
                    onClick={handleDiscoverMenuOpen}
                    sx={buttonStyle}
                    endIcon={
                      isDiscoverMenuOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    }
                  >
                    {t('Discover')}
                  </Button>
                  <DropdownMenu
                    anchorEl={discoverAnchorEl}
                    onClose={handleDiscoveryClose}
                    menuItems={
                      discoverMenuItems?.map((item, index) => ({
                        label: getLocalizedText(locale, item.name),
                        href: `/${getLocalizedText(
                          locale,
                          item.nameToLower
                        )}?currency=${currency.value}`,
                        key: index,
                      })) || []
                    }
                  />
                  {renderNavLink('/offers/1', t('OffersAndNews'))}
                  {/* {renderNavLink('/contact', t('Contact'))} */}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <CurrencySwitcher />
                  <LanguageSwitcher />
                </Box>

                {session?.user && <Notifications />}
                <UserBox />
              </Box>
            </Stack>
          </Toolbar>
          {mobileMenuOpen && <AppMobileHeaderMenu />}
        </Container>
      </AppBar>
    </ElevationScroll>
  );
};

export default Header;
