'use client';

import { useLocale, useTranslations } from 'next-intl';
import { usePathname, useRouter } from '@/utils/navigation';
import { useParams, useSearchParams } from 'next/navigation';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LanguageIcon from '@mui/icons-material/Language';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LanguageSwitcherProps } from './types';
import { PublishedLanguage } from '@/api/repositories/types/languageTypes';
import { useLanguageOptions } from '@/providers/LanguageOptionsProvider';
import { availableLocales } from '@/availableLocales';
import { saveLanguageAction } from './actions';
import { useSession } from 'next-auth/react';

const LanguageSwitcher = ({ isAdmin }: LanguageSwitcherProps) => {
  const locale = useLocale();
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();
  const params = useParams();
  const { data: session } = useSession();

  const handleChange = (event: SelectChangeEvent<typeof locale>) => {
    const queryString = searchParams.toString();
    const fullPathname = queryString ? `${pathname}?${queryString}` : pathname;
    const locals = availableLocales;
    // in case there is no locale file from i18nexus go to english
    const newLocale = locals.includes(event.target.value) ? event.target.value : 'en';

    const languageId = languageOptions?.find(
      (option: PublishedLanguage) => option.code === newLocale
    )?.id;

    session?.user && languageId && saveLanguageAction(languageId);

    // @ts-expect-error -- TypeScript will validate that only known `params`
    // are used in combination with a given `pathname`. Since the two will
    // always match for the current route, we can skip runtime checks.
    router.replace({ pathname: fullPathname, params }, { locale: newLocale });
  };

  const color = isAdmin ? 'darkBlueIcon' : 'surface';

  const { data: languageOptions } = useLanguageOptions();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          color,
          padding: '12px',
        }}
      >
        <LanguageIcon
          sx={{
            color,
            marginRight: 1,
            fontSize: 20,
            verticalAlign: 'middle',
          }}
        />
        <FormControl variant="standard">
          <Select
            onChange={handleChange}
            disableUnderline
            labelId="locale-select-label"
            id="locale-select"
            value={locale}
            sx={{
              color,
              '& .MuiSelect-icon': {
                color,
                fontSize: 20,
              },
            }}
            IconComponent={KeyboardArrowDownIcon}
            renderValue={(value) => value.toUpperCase()}
          >
            {(languageOptions || []).map((option: PublishedLanguage) => {
              if (availableLocales.includes(option.code)) {
                return (
                  <MenuItem value={option.code} key={option.code}>
                    {option.name}
                  </MenuItem>
                );
              }
              return null;
            })}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default LanguageSwitcher;
