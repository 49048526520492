import Image from 'next/image';

const horizontalColour1 = '/BT_Horizontal_Colour1.svg';
const horizontalWhite1 = '/BT_Horizontal_White_Yellow1.svg';

type LogoProps = {
  color?: 'white' | 'colour';
  width?: number;
  height?: number;
};

function Logo({color = 'white', width = 105, height = 42}: LogoProps) {
  const logoUrl = {
    white: horizontalWhite1,
    colour: horizontalColour1,
  };

  return (
    <Image
      src={logoUrl[color]}
      alt="balkan transfer"
      width={width}
      height={height}
    />
  );
}

export default Logo;
