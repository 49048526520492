import { parse } from 'node-html-parser';
import removeAccents from 'remove-accents';
// Types
import { TranslationType } from '@/api/repositories/types/translationTypes';
import {
  DestinationDetails,
  FixedLocation,
  FormatedTransferItems,
  Name,
} from '@/api/repositories/types/searchRideTypes';
import { PHONE_NUMBER_REGEX } from '@/utils/regex';

type DestinationInfoBoxProps = {
  data: {
    breakInfo: string;
    departureTime: string;
    departureDate: Date | null;
    departureLocation: string;
    arrivalTime: string;
    arrivalLocation: string;
    price: number;
    [key: string]: any;
  };
};

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const formatDate = (date: Date | string) => {
  // If the date is a string, convert it to a Date object
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${monthNames[monthIndex]} ${day}, ${year}`;
};

const formatTime = (time: string): string => {
  if (!time) return '';
  const [hours, minutes] = time.split(':');
  return `${hours}:${minutes}`;
};

const isAirportTransfer = (fromDestination: string, toDestination: string) => {
  const airportRegex = /airport/i;
  const fromDestinationMatch = fromDestination.match(airportRegex);
  const toDestinationMatch = toDestination.match(airportRegex);

  if (fromDestinationMatch || toDestinationMatch) {
    return true;
  } else {
    return false;
  }
};

const generatePassengers = (adultNumber: number, childrenNumber: number) => {
  const adultSelections = Array.from({ length: adultNumber }, (_, index) => {
    return {
      id: `selectAdult${index + 1}`,
      data: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        primary: index === 0 ? true : false,
        type: 'adult',
        number: index + 1,
        passenger: '',
      },
    };
  });

  const childSelections = Array.from({ length: childrenNumber }, (_, index) => {
    return {
      id: `selectChild${index + 1}`,
      data: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        email: '',
        phone: '',
        primary: false,
        type: 'child',
        number: index + 1,
        passenger: '',
      },
    };
  });

  const selectionObjects = adultSelections.concat(childSelections);

  return selectionObjects;
};

/**
 * This function is used to get the localized text from the array of objects
 * @param locate - the language code
 * @param array - the array of objects
 * @returns the localized text
 * @example getLocalizedText('en', [{lang: 'en', text: 'Hello'}, {lang: 'de', text: 'Hallo'}])
 * returns 'Hello' if the locate is 'en' and 'Hallo' if the locate is 'de'
 * */
const getLocalizedText = (
  locate: string,
  array: any[] | TranslationType[]
): string => {
  if (!array || !array.length) return '';
  const localizedText = array.find((name) => name.lang === locate);
  const englishTranslation = array.find((name) => name.lang === 'en');
  return localizedText ? localizedText.text : (englishTranslation?.text ?? '');
};

const filterPropsByKeyword = (
  object: Record<string, any>,
  keyword: string,
  departureDate: Date | null
): DestinationInfoBoxProps['data'] => {
  const filteredObject: DestinationInfoBoxProps['data'] = {
    breakInfo: '',
    departureTime: '',
    departureDate,
    departureLocation: '',
    arrivalTime: '',
    arrivalLocation: '',
    price: 0,
  };

  for (const prop in object) {
    if (
      Object.prototype.hasOwnProperty.call(object, prop) &&
      prop.includes(keyword)
    ) {
      let key = prop.replace(keyword, '');
      key = key.charAt(0).toLowerCase() + key.slice(1);
      if (key in filteredObject) {
        if (key === 'price') {
          filteredObject[key] = parseFloat(object[prop]);
        } else {
          filteredObject[key] = object[prop];
        }
      }
    }
  }

  return filteredObject;
};

// Convert all words to lowercase and capitalize the first character of each word except the first one
const modifyString = (inputString: string): string => {
  const words = inputString.split(' ');
  const modifiedString = words
    .map((word, index) =>
      index === 0
        ? word.toLowerCase()
        : word.charAt(0).toLowerCase() + word.slice(1)
    )
    .join('');

  return modifiedString;
};

/**
 *
 * @param text - {string}
 * @returns  {text.replace(/\s/g, '')} - {string}
 * @example removeTextSpace('Mostar ') returns 'Mostar'
 */
const removeTextSpace = (text: string) => {
  return text.replace(/\s/g, '');
};

/**
 *
 * @param htmlString - {string}
 * @returns {root.text} - {string}
 * @example   getHtmlString(<p>This is paraph with tag</p>) returns 'This is paraph with tag'
 */
const getHtmlString = (htmlString: string) => {
  const root = parse(htmlString);
  return root.text;
};

const minutesToTravelTime = (
  minutes: number,
  hourText: string,
  minuteText: string
) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours}${hourText || 'h'} ${mins}${minuteText || 'm'}`;
};

/**
 * Set variable to local storage
 *
 * Usage
 * this.$helpers.setLocalStorage(key, value);
 *
 * @param key string
 * @param value string
 * @returns void function
 */
const setLocalStorage = (key: string, value: string) => {
  // delete key from local storage if exist
  if (localStorage.getItem(key)) localStorage.removeItem(key);
  // create new key with value
  localStorage.setItem(key, value);
};

/**
 * Delete variable from local storage
 *
 * Usage
 * this.$helpers.deleteLocalStorage(key);
 *
 * @param key string
 * @returns void function
 */
const deleteLocalStorage = (key: string) => {
  if (localStorage.getItem(key)) localStorage.removeItem(key);
};

const getCurrencyLabel = (currency: string) => {
  switch (currency) {
    case 'EUR':
      return '€';
    case 'BAM':
      return 'BAM';
    default:
      return '€';
  }
};

const isValidEmail = (email: string) => {
  // Basic email format validation using regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isValidPhoneNumber = (phone: string) => {
  // Check if phone number length is at least 7 characters
  // 7 chars is the "+" and the 6 number
  //remove the spaces before checking the length and see if it matches our regex for phone num
  const phoneNum = phone.trim();
  return (
    phoneNum.length >= 7 &&
    phoneNum.length <= 16 &&
    phoneNum.match(PHONE_NUMBER_REGEX)
  );
};

function splitArray(array: any[], chunkSize = 50) {
  const result = [];
  let i = 0;

  while (i < array.length) {
    result.push(array.slice(i, i + chunkSize));
    i += chunkSize;
  }
  return result;
}

/**
 *
 * @param translateName - {TranslationType[], string}
 * @returns {TranslationType[]}
 * @example mapSegments([{lang: 'en', text: 'Segments'}]) returns [{lang: 'en', text: 'Transfers'}]
 * @example mapSegments([{lang: 'bs', text: 'Segmenti'}]) returns [{lang: 'bs', text: 'Transferi'}]
 */
function mapSegments(translateName: TranslationType[], locale: string) {
  // if translateName have text 'Segments' return 'Transfers',
  // if have 'Segmenti' return 'Transferi'
  let result = translateName;
  translateName.forEach((item) => {
    if (item.lang === locale) {
      if (item.text === 'Segments') {
        result = [{ lang: item.lang, text: 'Transfers' }];
      }
      if (item.text === 'Segmenti') {
        result = [{ lang: item.lang, text: 'Transferi' }];
      }
    }
  });
  return result;
}

const checkIfDestinationHasName = (
  destination: DestinationDetails | FixedLocation,
  query: string,
  isFixedLocation: boolean = false,
  destinationName?: Name[]
) =>
  destination.name.some((name) => {
    return checkIfNameHasSearchTerm(
      query,
      name.text,
      isFixedLocation,
      destinationName
    );
  });

const normalizeString = (str: string) =>
  removeAccents(str.toLowerCase().trim().replace(/\s+/g, ' '));

const checkIfNameHasSearchTerm = (
  searchTerm: string,
  option: string,
  isFixedLocation: boolean = false,
  destinationName?: Name[]
) => {
  const normalizedSearchTerm = normalizeString(searchTerm);
  const searchTermWords = normalizedSearchTerm.split(' ');
  if (isFixedLocation) {
    return destinationName?.some((name) => {
      // Createing a string to be checked for the search destinations
      const fixedLocationOption = normalizeString(`${name.text} - ${option}`);
      return checkIfTheWordIsIncludedInSearchTerm(
        searchTermWords,
        fixedLocationOption
      );
    });
  } else {
    const normailzedOption = normalizeString(option);
    // check if all words in the searchTerm are present in the option
    return checkIfTheWordIsIncludedInSearchTerm(
      searchTermWords,
      normailzedOption
    );
  }
};

const checkIfTheWordIsIncludedInSearchTerm = (
  searchTermWords: string[],
  option: string
) => {
  return searchTermWords.every((word) => {
    // check if the option contains the word
    return option.includes(word);
  });
};

const shouldDisplayOption = (
  searchTerm: string,
  option: string,
  isOptionTomTomSearch: boolean = false
) => {
  if (isOptionTomTomSearch) return true;
  return checkIfNameHasSearchTerm(searchTerm, option);
};

const filterDestionationsInAutoComplete = (options: Array<any>, state: any) => {
  return options.filter((option) => {
    if (option.isRecentSearch) {
      return true;
    }
    return shouldDisplayOption(
      state.inputValue,
      option.name,
      option.isTomTomSearchResult
    );
  });
};

const getTransferIndexWithClosestTime = (
  filterDateString: string,
  transferList: FormatedTransferItems[],
  isArriveAt: boolean | null = false
) => {
  const filterDate = new Date(filterDateString).getTime();
  // Initialize variables to track the closest date
  let closestTransferIndex = -1;
  let minDifference = Infinity;

  // Iterate through the array to find the closest date
  transferList.forEach((item: FormatedTransferItems, index: number) => {
    const itemDate = new Date(
      isArriveAt ? item.departureDate : item.arrivalDateTime
    );
    const difference = Math.abs(itemDate.getTime() - filterDate);

    if (difference < minDifference) {
      minDifference = difference;
      closestTransferIndex = index;
    }
  });

  return closestTransferIndex;
};

const validateDigitsOnly = (value: string) => {
  return /^\d*$/.test(value); // regex to match digits only
};

export {
  formatDate,
  isAirportTransfer,
  getLocalizedText,
  filterPropsByKeyword,
  generatePassengers,
  modifyString,
  removeTextSpace,
  getHtmlString,
  minutesToTravelTime,
  formatTime,
  setLocalStorage,
  deleteLocalStorage,
  getCurrencyLabel,
  isValidEmail,
  isValidPhoneNumber,
  splitArray,
  mapSegments,
  monthNames,
  checkIfDestinationHasName,
  filterDestionationsInAutoComplete,
  getTransferIndexWithClosestTime,
  validateDigitsOnly,
};
