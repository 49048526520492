import {formatDistanceToNow} from 'date-fns';

import {alpha} from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {PushNotificationDto} from '@/api/repositories/types/pushNotificationTypes';
import NotificationsIcon from '@mui/icons-material/Notifications';

export type NotificationProps<T = PushNotificationDto> = {
  title: string;
  text: string;
  time: string;
  isRead?: boolean;
  icon?: React.ReactNode;
  dtoRef?: T;
};

const Notification = ({title, text, time, isRead}: NotificationProps) => {
  return (
    <Box
      display="flex"
      flex="1"
      flexDirection={'row'}
      sx={{
        backgroundColor: isRead ? 'webSecondaryContainer' : 'transparent',
        padding: '16px',
        paddingRight: '24px',
      }}
    >
      <Box mr={2} display={'flex'} justifyContent={'center'}>
        <NotificationsIcon
          sx={{
            backgroundColor: 'primary.main',
            color: 'surface',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            padding: '8px',
          }}
        />
      </Box>

      <Box display="flex" flexGrow={1} flexDirection="column">
        {title ? (
          <Typography variant="bodyRegular" fontWeight={600}>
            {title}
          </Typography>
        ) : null}
        {text ? (
          <Typography variant="bodyRegular" component="p" fontWeight={400}>
            {text}
          </Typography>
        ) : null}
        <Typography
          align="right"
          variant="bodyExtraSmall"
          color={alpha('#18236299', 0.6)}
          mt={1}
        >
          {formatDistanceToNow(new Date(time), {
            addSuffix: true,
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default Notification;
