import {PushNotificationDto} from './types/pushNotificationTypes';
import {PaginatedPayload} from './types/commonTypes';
import {httpClient} from '@/api/httpClient';

const appResource = '/app';

export const registerForPushNotificationsAsync = async (payload: {
  token: string;
  deviceID: string;
  platform: 'ios' | 'android' | 'web';
}): Promise<undefined> => {
  if (!httpClient) return;

  const response = await httpClient.post(
    `${appResource}/push-notification/register`,
    {},
    {params: payload}
  );

  return response.data;
};

export const unregisterForPushNotificationsAsync = async (payload: {
  token: string;
}): Promise<undefined> => {
  if (!httpClient) return;

  const response = await httpClient.post(
    `${appResource}/push-notification/unregister`,
    {},
    {params: payload}
  );

  return response.data;
};

export const listUserNotifications = async (
  payload: PaginatedPayload
): Promise<PushNotificationDto[]> => {
  if (!httpClient) return [];

  const response = await httpClient.get<PushNotificationDto[]>(
    `${appResource}/push-notification/list-users-notification`,
    {params: payload}
  );

  return response.data;
};

export const markNotificationsAsRead = async (): Promise<undefined> => {
  if (!httpClient) return;

  const response = await httpClient.post(
    `${appResource}/push-notification/mark-notifications-as-read`
  );

  return response.data;
};

export const markNotificationAsRead = async (
  id: number
): Promise<undefined> => {
  if (!httpClient) return;

  const response = await httpClient.post(
    `${appResource}/push-notification/mark-notification-as-read/${id}`
  );

  return response.data;
};

export const PushNotificationKeys = {
  MARK_NOTIFICATION_AS_READ: 'MARK_NOTIFICATION_AS_READ',
  MARK_NOTIFICATIONS_AS_READ: 'MARK_NOTIFICATIONS_AS_READ',
  LIST_USER_NOTIFICATIONS: 'LIST_USER_NOTIFICATIONS',
  UNREGISTER_FOR_PUSH_NOTIFICATIONS_ASYNC:
    'UNREGISTER_FOR_PUSH_NOTIFICATIONS_ASYNC',
  REGISTER_FOR_PUSH_NOTIFICATIONS_ASYNC:
    'REGISTER_FOR_PUSH_NOTIFICATIONS_ASYNC',
} as const;
