import {usePushNotifications} from '@/push-notifications/usePushNotifications';
import {signOut} from 'next-auth/react';

export const useClientAuthMethods = () => {
  const {unregisterForPushNotifications} = usePushNotifications();

  const logout: typeof signOut = async (...args) => {
    const res = await signOut(...args);

    // do something after logout
    // unregister push notifications
    unregisterForPushNotifications();
    return res;
  };

  return {
    signOut: logout,
  };
};
