import { usePathname } from '@/utils/navigation';
import useStoreCleaning from './useStoreCleaning';
import useSearchTransfer from './useSearchTransfer';
import { getSession, useSession } from 'next-auth/react';
import {
  getUserCurrency,
  saveCurrency,
} from '@/api/repositories/accountSettingsRepository';
import { getCurrencyLabel } from '../helpers/helpers';
import { useCurrencySlice, useSearchRide } from '@/store/store';
import { httpClient } from '@/api/httpClient';

const useCurrencySwitcher = () => {
  const { data: session } = useSession();
  const path = usePathname();

  const [currency, setCurrency] = useCurrencySlice((state) => [
    state.currency,
    state.setCurrency,
  ]);

  const {
    resetSearchResult,
    resetTickets,
    resetPassengers,
    resetPaymentInfo,
    resetUpdateBooking,
  } = useStoreCleaning();

  const { getTransfers } = useSearchTransfer();

  const {
    selectFromDestination,
    selectToDestination,
    passengerInfo,
    addReturnTrip,
    isAssistanceNeeded,
    isDepartingFrom,
    isDepartingFromReturn,
    arrivingDate,
    arrivingTime,
    returnDate,
    returnTime,
  } = useSearchRide();

  const transferDetails = {
    selectFromDestination,
    selectToDestination,
    passengerInfo,
    addReturnTrip,
    isAssistanceNeeded,
    isDepartingFrom,
    isDepartingFromReturn,
    arrivingDate,
    arrivingTime,
    returnDate,
    returnTime,
  };

  const setAuthToken = (token: string) => {
    if (token) {
      httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete httpClient.defaults.headers.common['Authorization'];
    }
  };

  const checkCurrency = async () => {
    const timeoutId = setTimeout(async () => {
      const recSession = await getSession();
      if (recSession?.accessToken) {
        setAuthToken(recSession.accessToken);
        const response = await getUserCurrency();
        if (response) {
          const addCurrency = {
            value: response,
            label: getCurrencyLabel(response),
          };
          setCurrency(addCurrency);
        } else {
          setCurrency({
            value: 'EUR',
            label: '€',
          });
        }
      }
      clearTimeout(timeoutId);
    }, 500);
  };

  const saveUserCurrency = async (currency: string) => {
    const recSession = await getSession();
    if (recSession?.accessToken) {
      setAuthToken(recSession.accessToken);
      await saveCurrency(currency);
    }
  };

  const handleCurrencyChange = async (newCurrency: string) => {
    if (newCurrency === currency.value) return;

    const addCurrency = {
      value: newCurrency,
      label: newCurrency === 'EUR' ? '€' : 'BAM',
    };

    if (session?.user) await saveUserCurrency(addCurrency.value);

    setCurrency(addCurrency);
  };

  return {
    checkCurrency,
    saveUserCurrency,
    handleCurrencyChange,
  };
};

//* Method to compare the currency from API and currently set currency
//? Multiple instances of user can be switched at the same time - resulting in difference between the currency set locally and through bacend.

export const useCompareCurrency = () => {
  const [currency, setCurrency] = useCurrencySlice((state) => [
    state.currency,
    state.setCurrency,
  ]);
  const compareCurrency = (receivedCurr: string) => {
    if (receivedCurr === currency.value) return;
    setCurrency({
      value: receivedCurr,
      label: getCurrencyLabel(receivedCurr),
    });
  };

  return {
    compareCurrency,
  };
};

export default useCurrencySwitcher;
