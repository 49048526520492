import { getTransferList } from '@/api/repositories/searchRideRepository';
import { TripDetails } from '@/api/repositories/types/searchRideTypes';

export const searchTransfers = async (
  payload: TripDetails,
  setLoading: Function,
  sucessCallback: Function
) => {
  try {
    setLoading(true);
    const res = await getTransferList(payload);
    sucessCallback(res);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    console.error(e);
  }
};
