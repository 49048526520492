import { httpClient } from '../httpClient';
import { ChangePasswordType, NotificationType } from './types/accountSettingsTypes';
import { SetNotificationType } from './types/accountSettingsTypes';
import { DeleteAccountType } from './types/accountSettingsTypes';

// Notifications
export const getNotificationSettings = async (): Promise<NotificationType[]> => {
  const response = await httpClient.get('/app/user-notification-settings');
  return response.data;
};

export const setNotificationSettings = async (changedNotifications: SetNotificationType[]) => {
  const response = await httpClient.post(
    '/app/user-notification-settings/set',
    changedNotifications
  );
  return response.data;
};

// Change password
export const changePassword = async (data: ChangePasswordType) => {
  const response = await httpClient.post('/account/my-profile/change-password', data);
  return response.data;
};

// Delete account
export const deleteAccount = async (data: DeleteAccountType) => {
  // Encode password to avoid special characters (e.g. #)
  const encodedPassword = encodeURIComponent(data.password);

  const response = await httpClient.put(
    `/app/customer-profile/deactivate-customer-account?reason=${data.reason}&password=${encodedPassword}`
  );
  return response.data;
};

// save currency for user
export const saveCurrency = async (currency: string) => {
  const response = await httpClient.put(
    `/app/customer-profile/save-currency-preference/${currency}`
  );
  return response.data;
};

export const saveLanguage = async (languageId: number) => {
  const response = await httpClient.put(
    `/app/customer-profile/save-language-preference/${languageId}`
  );
  return response.data;
};

// get user currency
export const getUserCurrency = async () => {
  const response = await httpClient.get('/app/customer-profile/user-currency');
  return response.data;
};

// Query keys
export const AccountSettingsQueryKeys = {
  NOTIFICATION_SETTINGS: 'NOTIFICATION_SETTINGS',
  DELETE_ACCOUNT_REASON: 'DELETE_ACCOUNT_REASON',
};
