import { availableLocales } from '@/availableLocales';
import { Pathnames } from 'next-intl/navigation';
export const defaultLocale = 'en' as const;
// These locales should only be edited for routes, locales from i18nexus are in availableLocales.ts file
export const locales = [...availableLocales] as const;
// always | never | as-needed
export const localePrefix = 'as-needed'; // hide default locale prefix

//* Ensure translations - for locales that are not defined in the pathnames object, add the English version
function ensureTranslations(
  pathnames: Pathnames<typeof availableLocales>,
  locales: string[]
): Pathnames<typeof availableLocales> {
  const completedPathnames: Pathnames<typeof availableLocales> = {
    ...pathnames,
  };

  for (const [path, translations] of Object.entries(completedPathnames)) {
    if (typeof translations === 'object') {
      locales.forEach((locale) => {
        if (!translations[locale]) {
          translations[locale] = translations['en'];
        }
      });
    }
  }

  return completedPathnames;
}

// Remove the type definition here if you want to enforce only the path names defined in this object.
// Otherwise, this setup will allow you to use any path name.
export const pathnamesMock: Pathnames<typeof availableLocales> = {
  '/': '/',

  '/blog/[subSlug]': '/blog/[subSlug]',
  // If locales use different paths, you can
  // specify each external path per locale.

  '/transfers': {
    en: '/transfers',
    bs: '/transferi',
  },

  '/transfers/[subSlug]': {
    en: '/transfers/[subSlug]',
    bs: '/transferi/[subSlug]',
  },

  '/destinations': {
    en: '/destinations',
    bs: '/destinacije',
  },

  '/destinations/[subSlug]': {
    en: '/destinations/[subSlug]',
    bs: '/destinacije/[subSlug]',
  },

  '/about-us': {
    en: '/about-us',
    bs: '/o-nama',
  },

  '/safety-and-quality': {
    en: '/safety-and-quality',
    bs: '/sigurnost-kvalitet',
  },

  '/faq': {
    en: '/faq',
    bs: '/cesto-postavljena-pitanja',
  },

  '/contact': {
    en: '/contact',
    bs: '/kontakt',
  },

  '/terms-and-service': {
    en: '/terms-and-service',
    bs: '/uslovi-koriscenja',
  },

  '/privacy-policy': {
    en: '/privacy-policy',
    bs: '/politika-privatnosti',
  },

  '/cookie-policy': {
    en: '/cookie-policy',
    bs: '/politika-kolacica',
  },

  '/profile': {
    en: '/profile',
    bs: '/profil',
  },

  '/profile/my-transfers': {
    en: '/profile/my-transfers',
    bs: '/profil/moje-transferi',
  },

  '/profile/settings': {
    en: '/profile/settings',
    bs: '/profil/podesavanja',
  },

  '/offers/[page]': {
    en: '/offers/[page]',
    bs: '/ponude/[page]',
  },

  '/offers/[page]/[slug]': {
    en: '/offers/[page]/[slug]',
    bs: '/ponude/[page]/[slug]',
  },

  '/search-result': {
    en: '/search-result',
    bs: '/rezultati-pretrage',
  },

  // // Pathnames that overlap with dynamic segments should
  // // be listed first in the object, as the middleware will
  // // pick the first matching entry.
  // '/news/just-in': {
  //   en: '/news/just-in',
  //   bs: '/neuigkeiten/aktuell',
  // },

  // // Dynamic params are supported via square brackets
  // '/news/[articleSlug]-[articleId]': {
  //   en: '/news/[articleSlug]-[articleId]',
  //   de: '/neuigkeiten/[articleSlug]-[articleId]',
  // },

  // // Also (optional) catch-all segments are supported
  // '/categories/[...slug]': {
  //   en: '/categories/[...slug]',
  //   bs: '/kategorien/[...slug]',
  // },
};

export const pathnames = ensureTranslations(
  pathnamesMock,
  availableLocales
) satisfies Pathnames<typeof availableLocales>;

export type AppPathnames = keyof typeof pathnames;
