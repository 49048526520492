import { useState, useRef, useEffect } from 'react';
import { Link } from '@/utils/navigation';
import { getSession, useSession } from 'next-auth/react';
import { useLocale, useTranslations } from 'next-intl';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import { useMediaQuery, useTheme } from '@mui/material';

// Store
import { useCurrencySlice, useSearchRide } from '@/store/store';

// API & React Query
import { UserProfileQueryKeys, getCustomerProfile } from '@/api/repositories/userProfileRepo';
import { useQuery } from '@tanstack/react-query';

// Helpers
import { WEB_ROUTES } from '../../helpers/routes';
import { httpClient } from '@/api/httpClient';
import { useClientAuthMethods } from '@/lib/useClientAuthMethods';
import RenderIf from '@/sharedComponents/render-if/RenderIf';
import { PublishedLanguage } from '@/api/repositories/types/languageTypes';
import { saveLanguageAction } from '@/sharedComponents/language-switcher/actions';
import { useLanguageOptions } from '@/providers/LanguageOptionsProvider';

const UserBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: session } = useSession();
  const { signOut } = useClientAuthMethods();
  const { setCanFetchRecentSearches } = useSearchRide();
  const { data: languageOptions } = useLanguageOptions();
  const locale = useLocale();
  const ref = useRef(null);
  const t = useTranslations('WebUserBox');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  //* Store
  const currency = useCurrencySlice((state) => state.currency);

  const setAuthToken = (token: string) => {
    if (token) {
      httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete httpClient.defaults.headers.common['Authorization'];
    }
  };

  // * React Query
  const { data: profileData, refetch: refetchProfileData } = useQuery({
    queryFn: async () => {
      const recSession = await getSession();
      if (recSession?.accessToken) {
        setAuthToken(recSession.accessToken);
      }
      const data = await getCustomerProfile();
      setCanFetchRecentSearches(true);
      if (!data.languageId) {
        const languageId = languageOptions?.find(
          (option: PublishedLanguage) => option.code === locale
        )?.id;
        languageId && (await saveLanguageAction(languageId));
      }
      return data;
    },
    queryKey: [UserProfileQueryKeys.USER_PROFILE],
  });

  const { profilePhoto } = profileData || {
    profilePhoto: {
      path: '',
    },
  };

  //* use Effect
  useEffect(() => {
    refetchProfileData();
  }, [currency.value]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const getUserInitials = (firstName: string, lastName: string) => {
    const name = `${firstName} ${lastName}`;
    const initials = name?.includes(' ')
      ? name
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase())
          .join('')
      : firstName?.charAt(0).toUpperCase();
    return initials;
  };

  return (
    <>
      {session?.user ? (
        <>
          <Button
            onClick={handleOpen}
            sx={{
              color: 'inherit',
              textTransform: 'none',
              ml: isMobile ? 0 : 1,
              mr: isMobile ? 0 : 1,
            }}
            ref={ref}
          >
            {profilePhoto?.path ? (
              <Avatar
                alt="Profile Photo"
                src={
                  process.env.NEXT_PUBLIC_API_URL +
                  `/api/app/media-file-public/preview?filePath=${profilePhoto.path}`
                }
                sx={{ width: 36, height: 36 }}
              />
            ) : (
              <Avatar
                alt="Profile Photo"
                sx={{
                  backgroundColor: 'lightBlue',
                  color: 'primary',
                  width: 36,
                  height: 36,
                }}
              >
                <Typography variant="body1" fontWeight={500} color="primary">
                  {getUserInitials(session.user.firstName, session?.user.lastName)}
                </Typography>
              </Avatar>
            )}
            <RenderIf condition={!isMobile}>
              <Box sx={{ ml: '8px' }}>{isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</Box>
            </RenderIf>
          </Button>
        </>
      ) : !isMobile ? (
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <PersonOutlineOutlinedIcon />

          <Typography sx={{ display: { xs: 'none', md: 'block' } }}>
            <Link href="/login">{t('Login')}</Link>
          </Typography>
        </Box>
      ) : (
        <Link href="/login">
          <PersonOutlineOutlinedIcon />
        </Link>
      )}

      <Popover
        anchorEl={ref.current}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <List component="nav">
            <Link href="/profile">
              <ListItemButton>
                <PersonOutlineOutlinedIcon />
                <ListItemText sx={{ ml: 1 }} primary={t('MyProfile')} />
              </ListItemButton>
            </Link>
            <Link href="/profile/my-transfers">
              <ListItemButton>
                <HistoryOutlinedIcon />
                <ListItemText sx={{ ml: 1 }} primary={t('MyTransfers')} />
              </ListItemButton>
            </Link>
            {/* //*TODO: Uncomment Comnapions redirects when page available (Phase 2) */}
            {/* <Link href="">
              <ListItemButton>
                <Diversity3OutlinedIcon />
                <ListItemText sx={{ml: 1}} primary={t('Companions')} />
              </ListItemButton>
            </Link> */}
            <Link href={WEB_ROUTES.PROFILE_SETTINGS}>
              <ListItemButton>
                <ManageAccountsOutlinedIcon />
                <ListItemText sx={{ ml: 1 }} primary={t('Settings')} />
              </ListItemButton>
            </Link>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <ListItemButton
              onClick={() => {
                signOut({ redirect: false }), handleClose();
              }}
            >
              <LogoutOutlinedIcon />
              <ListItemText sx={{ ml: 1 }} primary={t('Logout')} />
            </ListItemButton>
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default UserBox;
