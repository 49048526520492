'use client';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslations } from 'next-intl';

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import Notification, { NotificationProps } from './Notification';
import { usePushNotifications } from '@/push-notifications/usePushNotifications';
import { CloseOutlined } from '@mui/icons-material';

const Notifications = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const {
    userNotificationQuery,
    numberOfUnreadNotifications,
    markNotificationsAsRead,
  } = usePushNotifications({ fetchEnabled: true });

  const handleOpen = () => {
    setIsOpen(true);
    if (markNotificationsAsRead.isIdle && numberOfUnreadNotifications > 0) {
      console.debug('[Notifications] -> Marking notifications as read!');
      markNotificationsAsRead.mutate();
    }
  };
  const handleClose = () => setIsOpen(false);

  const t = useTranslations('Notifications');

  const notificationList: NotificationProps[] = useMemo(() => {
    if (
      !userNotificationQuery.data ||
      !Array.isArray(userNotificationQuery.data) ||
      !userNotificationQuery.data.length
    ) {
      return [];
    }

    const sorted = userNotificationQuery.data.sort(
      (a, b) =>
        new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime()
    );

    const mapped = sorted.map((notification) => {
      const mappedNotification: NotificationProps = {
        title: notification.title ?? '',
        text: notification.message ?? '',
        time: notification.creationTime,
        dtoRef: notification,
      };
      return mappedNotification;
    });

    return mapped;
  }, [userNotificationQuery.data]);

  return (
    <>
      <IconButton
        color="inherit"
        sx={{ ml: 1, mr: 1 }}
        ref={ref}
        onClick={handleOpen}
      >
        <Badge badgeContent={numberOfUnreadNotifications} color="error">
          <NotificationsActiveOutlinedIcon />
        </Badge>
      </IconButton>

      <Popover
        anchorEl={ref.current}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography fontWeight={500} variant="bodyLargeRegular">
            {t('title')}
          </Typography>

          <IconButton sx={{}} onClick={handleClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Divider />

        <List
          sx={{
            li: {
              minWidth: 260,
              maxWidth: 480,
            },
          }}
        >
          {notificationList.map((notification, index) => (
            <ListItem key={index} sx={{ padding: 0 }}>
              <Notification {...notification} />
            </ListItem>
          ))}
        </List>

        {notificationList.length === 0 && (
          <Box
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography variant="bodyRegular">
              {t('noNotifications')}
            </Typography>
          </Box>
        )}
      </Popover>
    </>
  );
};

export default Notifications;
