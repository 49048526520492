'use client';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useCurrencySlice} from '@/store/store';
import useCurrencySwitcher from '@/app/[locale]/(web)/hooks/useCurrencySwitcher';
import {useTranslations} from 'next-intl';
import {useMediaQuery, useTheme} from '@mui/material';

const CurrencySwitcher = () => {
  const t = useTranslations('WebHomePage');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {handleCurrencyChange} = useCurrencySwitcher();

  // * Currency slice
  const [currency] = useCurrencySlice(state => [state.currency]);

  //* Methods
  // const handleCurrencySwitch = async (event: SelectChangeEvent) => {
  //   if (event.target.value === currency.value) return;

  //   const addCurrency = {
  //     value: event.target.value,
  //     label: event.target.value === 'EUR' ? '€' : 'BAM',
  //   };

  //   if (session?.user) {
  //     await saveUserCurrency(addCurrency.value);
  //   }
  //   setCurrency(addCurrency);
  //   setTimeout(async () => {
  //     await pathChecker();
  //   }, 200);
  // };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'surface',
          padding: '12px',
        }}
      >
        <CurrencyExchangeIcon
          sx={{
            color: 'surface',
            marginRight: 1,
            fontSize: 20,
            verticalAlign: 'middle',
          }}
        />
        <FormControl variant="standard">
          <Select
            onChange={(event: SelectChangeEvent) =>
              handleCurrencyChange(event.target.value)
            }
            disableUnderline
            labelId="locale-select-label"
            id="locale-select"
            value={currency.value}
            sx={{
              color: 'surface',
              '& .MuiSelect-icon': {
                color: 'surface',
                fontSize: 20,
              },
            }}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value="EUR">{t('Euro')}</MenuItem>
            <MenuItem value="BAM">
              {isMobile ? t('BosnianMarkMobile') : t('BosnianMark')}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default CurrencySwitcher;
