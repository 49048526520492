'use client';

import { getPublishedLanguages } from '@/api/repositories/languageRepository';
import { PublishedLanguage } from '@/api/repositories/types/languageTypes';
import React, { createContext, useContext, useState, useEffect } from 'react';

const LanguageOptionsContext = createContext<
  | {
      data: PublishedLanguage[] | null;
      error: Error | null;
    }
  | undefined
>(undefined);

export const LanguageOptionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [languageOptions, setLanguageOptions] = useState<
    PublishedLanguage[] | null
  >(null);
  const [error, setError] = useState<Error | null>(null);

  const fetchPublishedLanguages = async () => {
    try {
      const result = await getPublishedLanguages();
      setLanguageOptions(result);
    } catch (err) {
      setError(err as Error);
    }
  };

  useEffect(() => {
    fetchPublishedLanguages();
  }, []);

  return (
    <LanguageOptionsContext.Provider value={{ data: languageOptions, error }}>
      {children}
    </LanguageOptionsContext.Provider>
  );
};

export const useLanguageOptions = () => {
  const context = useContext(LanguageOptionsContext);
  if (!context) {
    throw new Error(
      'useLanguageOptions must be used within a LanguageOptionsProvider'
    );
  }
  return context;
};
