import { httpClient } from '../httpClient';
import { GetLanguagesResponse, Language, PublishedLanguage } from './types/languageTypes';

export const getLanguages = async (): Promise<GetLanguagesResponse> => {
  const response = await httpClient.get('/app/language');
  return response.data;
};

export const getActiveLanguages = async (): Promise<GetLanguagesResponse> => {
  const response = await httpClient.get('/app/language/active-languages');
  return response.data;
};

export const addNewLanguage = async (data: { name: string; code: string }) => {
  const response = await httpClient.post('/app/language/add', data);
  return response.data;
};

export const activateLanguage = async (data: { languageId: number; activate: boolean }) => {
  const response = await httpClient.put('/app/language/activate-language', {
    id: data.languageId,
    isActive: data.activate,
  });
  return response.data;
};

export const updateLanguage = async (data: { id: number; name: string; code: string }) => {
  const response = await httpClient.post('/app/language/update-information', data);
  return response.data;
};

export const publishLanguage = async (data: { languageId: number; activate: boolean }) => {
  const response = await httpClient.put('/app/language/publish-language', {
    id: data.languageId,
    isPublished: data.activate,
  });
  return response.data;
};

export const getPublishedLanguages = async (): Promise<PublishedLanguage[]> => {
  const response = await httpClient.get('/app/language/get-published');
  return response.data;
};

export const getLanguageById = async (id: number): Promise<Language> => {
  const response = await httpClient.get(`/app/language/${id}`);
  return response.data;
};

export const languageQueryKeys = {
  LANGUAGES: 'LANGUAGES',
  ACTIVE_LANGUAGES: 'ACTIVE_LANGUAGES',
  PUBLISHED_LANGUAGES: 'PUBLISHED_LANGUAGES',
  LANGUAGE_BY_ID: 'LANGUAGE_BY_ID',
} as const;
