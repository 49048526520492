import {
  useSearchResult,
  useSearchRide,
  useTicketSlice,
  usePassengersSlice,
  useBookingTimerSlice,
  usePaymentInfoSlice,
  useUpdateBooking,
  useLocationPicker,
  useSearchTrackingData,
} from '@/store/store';

const useStoreCleaning = () => {
  const { reset: resetSearchResult } = useSearchResult();
  const { reset: resetSearchRide } = useSearchRide();
  const { reset: resetTickets } = useTicketSlice();
  const { reset: resetPassengers } = usePassengersSlice();
  const { reset: resetBookingTimer } = useBookingTimerSlice();
  const { reset: resetPaymentInfo } = usePaymentInfoSlice();
  const { reset: resetUpdateBooking } = useUpdateBooking();
  const { reset: resetLocationPicker } = useLocationPicker();
  const { reset: resetSearchTrackingData } = useSearchTrackingData();

  const cleanBookingStore = () => {
    resetSearchResult();
    resetSearchRide();
    resetTickets();
    resetPassengers();
    resetBookingTimer();
    resetPaymentInfo();
    resetUpdateBooking();
    resetLocationPicker();
    resetSearchTrackingData();
  };

  return {
    cleanBookingStore,
    resetSearchResult,
    resetSearchRide,
    resetTickets,
    resetPassengers,
    resetBookingTimer,
    resetPaymentInfo,
    resetUpdateBooking,
    resetLocationPicker,
    resetSearchTrackingData,
  };
};

export default useStoreCleaning;
