import { ModFixedLocation } from '@/api/repositories/types/searchRideTypes';
import { TranslationType } from '@/api/repositories/types/translationTypes';
import { availableLocales } from '@/availableLocales';
import { format } from 'date-fns';

/**
 * This function is used to get the localized text from the array of objects
 * @param locate - the language code
 * @param array - the array of objects
 * @returns the localized text
 * @example getLocalizedText('en', [{lang: 'en', text: 'Hello'}, {lang: 'de', text: 'Hallo'}])
 * returns 'Hello' if the locate is 'en' and 'Hallo' if the locate is 'de'
 * */
const getLocalizedText = (locate: string, array: any[] | TranslationType[]): string => {
  if (!array || !array.length) return '';
  const localizedText = array.find((name) => name.lang === locate);
  const englishTranslation = array.find((name) => name.lang === 'en');
  return localizedText ? localizedText.text : (englishTranslation?.text ?? '');
};

const generateQueryParams = (params: any) => {
  if (params.length === 0) return '';
  let query = '?';
  params.forEach((param: any, index: number) => {
    const key = Object.keys(param)[0];
    if (param[key]) {
      query += `${key}=${param[key] + (index === params.length - 1 ? '' : '&')}`;
    }
  });
  return query;
};

const turnDateToISOString = (date: Date | null, isEndOfDay: boolean = false) => {
  let isoString = null;
  if (date) {
    isEndOfDay && date.setHours(23, 59, 59);
    isoString = date.toISOString();
  }
  return isoString;
};

const getDateFromISOString = (date: string): Date => {
  let dateFromIsoString = new Date(date);
  return dateFromIsoString;
};

const formatDate = (date: Date, dateFormat: string) => {
  return format(date, dateFormat);
};

const transferCoordinatesFromTomTomToGoogleMapsFormat = (location: {
  lat: number;
  long: number;
}) => {
  return { lat: location.lat, lng: location.long };
};

const transferAreaCoordinatesFromTomTomToGoogleMapsFormat = (area: any[]) => {
  return area?.map((point: { lat: number; long: number }) => {
    return transferCoordinatesFromTomTomToGoogleMapsFormat(point);
  });
};

const sortDestinationsByName = (destinations: ModFixedLocation[]) => {
  return destinations.sort((first, second) => {
    // sort airport destinations first, then city destinations
    if (first.isAirport !== second.isAirport) {
      return first.isAirport ? -1 : 1;
    }

    return first.name.localeCompare(second.name);
  });
};

const getSlugsForUseUpdateTranslatedUrl = (
  slug: TranslationType[],
  isSubSlug: boolean = false
): SlugsWithDynamicParam => {
  const slugs: SlugsWithDynamicParam = {
    dynamicParamName: isSubSlug ? 'subSlug' : 'slug',
  };
  availableLocales.forEach((locale) => {
    const existingSlug = slug.find((item) => item.lang === locale);
    if (existingSlug) {
      slugs[locale] = existingSlug.text;
    } else if (slug.length > 0) {
      slugs[locale] = slug[0].text; // Use the text from the first item
    }
  });
  return slugs;
};

export {
  getLocalizedText,
  generateQueryParams,
  turnDateToISOString,
  formatDate,
  getDateFromISOString,
  transferCoordinatesFromTomTomToGoogleMapsFormat,
  transferAreaCoordinatesFromTomTomToGoogleMapsFormat,
  sortDestinationsByName,
  getSlugsForUseUpdateTranslatedUrl,
};
