import { generateQueryParams } from '@/helpers/helpers';
import { httpClient } from '../httpClient';
import { UpdateUserProfile, UserProfile } from './types/userProfileTypes';
import { currencies } from '@/utils/currency';

export const getCustomerProfile = async (): Promise<UserProfile> => {
  const response = await httpClient.get(
    `/app/customer-profile/profile${generateQueryParams([{ currency: currencies.euro }])}`
  );
  return response.data;
};

export const updateCustomerProfile = async (
  data: UpdateUserProfile
): Promise<UpdateUserProfile> => {
  const response = await httpClient.put('/app/customer-profile/profile', data);
  return response.data;
};

export const uploadProfilePhoto = async (photo: File) => {
  const formData = new FormData();
  formData.append('file', photo);
  console.log('Image to upload:', formData);
  const response = await httpClient.post(
    '/app/customer-profile/upload-profile-photo',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data;
};

export const previewProfilePhoto = async (filePath: string): Promise<Blob> => {
  const response = await httpClient.get(
    `/app/media-file-public/preview?filePath=${filePath}`,
    { responseType: 'blob', headers: { 'Content-Type': 'image/*, */*' } }
  );
  return response.data;
};

export const UserProfileQueryKeys = {
  USER_PROFILE: 'USER_PROFILE',
  USER_PROFILE_PHOTO: 'USER_PROFILE_PHOTO',
};
