import { httpClient } from '@/api/httpClient';
import {
  AgeGroup,
  Destinations,
  TransferList,
  TransferListResponse,
  TripDetails,
} from './types/searchRideTypes';
import { generateQueryParams } from '@/helpers/helpers';

export const getDestinationsList = async (
  name: string
): Promise<Destinations> => {
  const queryParams = [{ name }];
  const response = await httpClient.get(
    `/app/destination-public/destination-list${generateQueryParams(queryParams)}`
  );
  return response.data;
};

export const getDestinationsToList = async (
  fromDestinationId: number
): Promise<Destinations> => {
  const queryParams = [{ fromDestinationId }];
  const response = await httpClient.get(
    `/app/destinations/to${generateQueryParams(queryParams)}`
  );
  return response.data;
};

export const getAgeGroups = async (): Promise<AgeGroup> => {
  const response = await httpClient.get('/app/age-group-public');
  return response.data;
};

export const getTransferList = async (
  payload: TripDetails
): Promise<TransferListResponse> => {
  const response = await httpClient.post(
    '/app/transfer-reservation-public/get-list',
    payload
  );
  return response.data;
};

export const SearchRideKeys = {
  DESTINATION_LIST: 'DESTINATION_LIST',
  AGE_GROUPS: 'AGE_GROUPS',
  TRANSFER_LIST: 'TRANSFER_LIST',
  TOMTOM_ADDRESS_LIST: 'TOMTOM_ADDRESS_LIST',
} as const;
