import { getAgeGroups } from '@/api/repositories/searchRideRepository';
import { useCurrencySlice, useSearchTransfersData } from '@/store/store';
import {
  TripDetails,
  TransferList,
  FormatedTransferItems,
  TransferListResponse,
  FlatDestinationDetails,
} from '@/api/repositories/types/searchRideTypes';
import {
  formatTime,
  getLocalizedText,
  getTransferIndexWithClosestTime,
  minutesToTravelTime,
} from '../helpers/helpers';
import { useLocale, useTranslations } from 'next-intl';
import { format } from 'date-fns';
import { searchTransfers } from '../actions/search-transfers/search-transfers';
import { PassengerCategoryInfo } from '@/store/types/types';
import { currencies } from '@/utils/currency';

interface getTransfersProps {
  selectFromDestination: FlatDestinationDetails;
  selectToDestination: FlatDestinationDetails;
  passengerInfo: PassengerCategoryInfo[];
  addReturnTrip: boolean;
  isAssistanceNeeded: boolean;
  isDepartingFrom: boolean | null;
  isDepartingFromReturn: boolean | null;
  arrivingDate: Date | null;
  arrivingTime: string;
  returnDate: Date | null;
  returnTime: string;
}

const useSearchTransfer = () => {
  const { transfers, setTransfers, isLoadingTransfers, setIsLoadingTransfers } =
    useSearchTransfersData();

  const locale = useLocale();
  const t = useTranslations('Transfers');
  const hourShort = t('hourShort');
  const minuteShort = t('minuteShort');

  const getAgeCategories = async () => {
    const response = await getAgeGroups();
    return response;
  };

  const generatePassengerInfo = async (
    passengerInfo: PassengerCategoryInfo[]
  ) => {
    const ageGroups = await getAgeCategories();

    return (
      ageGroups &&
      ageGroups?.items?.length > 0 &&
      passengerInfo
        .map((info) => {
          const matchingItem = ageGroups.items.find(
            (item) => item.ageGroupEnum === info.ageGroupEnum
          );

          if (matchingItem) {
            return {
              ageGroupId: matchingItem.id,
              number: info.number,
            };
          }

          return null;
        })
        .filter(Boolean)
    );
  };

  const generatePayload = async ({
    arrivingDate,
    returnDate,
    isDepartingFrom,
    isDepartingFromReturn,
    returnTime,
    arrivingTime,
    selectFromDestination,
    selectToDestination,
    addReturnTrip,
    isAssistanceNeeded,
    passengerInfo,
  }: getTransfersProps) => {
    const combineDateTime = (type: string, time: string) => {
      const arrivingDateFormat =
        typeof arrivingDate === 'object'
          ? arrivingDate
          : new Date(arrivingDate);

      const returnDateFormat =
        typeof returnDate === 'object' ? returnDate : new Date(returnDate);

      const date =
        type === 'arrival'
          ? arrivingDate
            ? arrivingDateFormat
            : new Date()
          : type === 'return'
            ? returnDateFormat
            : new Date();

      if (!date || !time) {
        console.error('Date or time is missing or empty.');
        return '';
      }
      const combinedDateTime = format(date, `yyyy-MM-dd'T'${time}:00`);
      return combinedDateTime;
    };

    const returnTripData = {
      isDepartingFromReturn,
      returnDate: combineDateTime('return', returnTime),
    };

    const passengers = await generatePassengerInfo(passengerInfo);

    const payload = {
      fromDestination: selectFromDestination?.parentId,
      toDestination: selectToDestination?.parentId,
      passengers,
      addReturnTrip,
      isAssistanceNeeded,
      date: combineDateTime('arrival', arrivingTime),
      isDepartingFrom,
      ...(addReturnTrip ? returnTripData : {}),
      currency: currencies.euro,
    } as TripDetails;

    Object.keys(payload).forEach(
      (key) =>
        (payload as { [key: string]: unknown })[key] ??
        delete (payload as { [key: string]: unknown })[key]
    );

    return payload;
  };

  const comparePrice = (oldPrice: number, newPrice: number) => {
    if (oldPrice > newPrice) {
      return Number(oldPrice.toFixed(2));
    } else {
      return 0;
    }
  };

  //* Compare dates to check if the transfer is on the same date (without date conversion)
  const compareDates = (initialDate: Date | '', resultDate: string) => {
    if (!initialDate) {
      console.error('Initial date is missing or empty.');
      return false;
    }

    const initialDateTransformed =
      typeof initialDate === 'object' ? initialDate : new Date(initialDate);

    const firstDate =
      initialDateTransformed instanceof Date
        ? `${initialDateTransformed.getFullYear()}-${String(
            initialDateTransformed.getMonth() + 1
          ).padStart(2, '0')}-${String(
            initialDateTransformed?.getDate()
          ).padStart(2, '0')}`
        : '';

    const secondDate = resultDate.slice(0, 10);

    return firstDate === secondDate;
  };

  const transferListResult = (
    newTransferList: TransferList[],
    arrivingDate: Date | null,
    returnDate: Date | null,
    transferFilters: getTransfersProps
  ) => {
    const formatTransferList = (): FormatedTransferItems[] => {
      return newTransferList.map((item: TransferList) => ({
        ...item,
        departureTime: formatTime(item.departureTime),
        departureDate: item.departureDate,
        arrivalTime: formatTime(item.arrivalTime),
        arrivalDate: item.arrivalDateTime,
        fromDestinationName: getLocalizedText(locale, item.fromDestinationName),
        toDestinationName: getLocalizedText(locale, item.toDestinationName),
        oldPrice: comparePrice(
          item.avgPriceWithGroupDiscount,
          item.avgPriceWithAllDiscounts
        ),
        price: Number(item.avgPriceWithAllDiscounts.toFixed(2)),
        transferDuration: minutesToTravelTime(
          item.travelTime,
          hourShort,
          minuteShort
        ),
        isSameTransferDate: compareDates(
          item.isOutboundTrip ? (arrivingDate ?? '') : (returnDate ?? ''),

          item.departureDate.toString()
        ),
        isClosestTime: false,
        isBookable: item.isBookable,
      }));
    };

    if (!newTransferList || newTransferList.length === 0)
      return { outboundTransferList: [], returnTransferList: [] };

    const formattedTransferList = formatTransferList();

    // Filter out duplicate objects from API, if any
    const uniqueObjects = new Set<string>();
    const filteredReturnTransferList = formattedTransferList.filter((item) => {
      const stringifiedItem = JSON.stringify(item);
      const isUnique = !uniqueObjects.has(stringifiedItem);
      if (isUnique) {
        uniqueObjects.add(stringifiedItem);
      }
      return isUnique;
    });

    const outboundTransferList = filteredReturnTransferList.filter(
      (item) => item.isOutboundTrip
    );
    const returnTransferList = filteredReturnTransferList.filter(
      (item) => !item.isOutboundTrip
    );

    const arrDate = format(
      new Date(transferFilters.arrivingDate as Date),
      `yyyy-MM-dd'T'${transferFilters.arrivingTime}:00`
    );
    const retDate = transferFilters.addReturnTrip
      ? format(
          new Date(transferFilters.returnDate as Date),
          `yyyy-MM-dd'T'${transferFilters.returnTime}:00`
        )
      : null;

    const outboundTransferWithClosestTime = getTransferIndexWithClosestTime(
      arrDate,
      outboundTransferList,
      transferFilters.isDepartingFrom
    );
    outboundTransferList[outboundTransferWithClosestTime].isClosestTime = true;

    if (transferFilters.addReturnTrip) {
      const returnTransferWithClosestTime = getTransferIndexWithClosestTime(
        retDate as string,
        returnTransferList,
        transferFilters.isDepartingFromReturn
      );
      returnTransferList[returnTransferWithClosestTime].isClosestTime = true;
    }

    return { outboundTransferList, returnTransferList };
  };

  const getTransfers = async (transferFilters: getTransfersProps) => {
    const onSuccessfulGetTransfers = (data: TransferListResponse) => {
      setTransfers(
        transferListResult(
          data.items,
          transferFilters.arrivingDate,
          transferFilters.returnDate,
          transferFilters
        )
      );
    };
    const payload = await generatePayload(transferFilters);
    await searchTransfers(
      payload,
      setIsLoadingTransfers,
      onSuccessfulGetTransfers
    );
  };

  return {
    transfers,
    getTransfers,
    isLoadingTransfers,
  };
};

export default useSearchTransfer;
