import { currencies } from '@/utils/currency';
import { httpClient } from '../httpClient';
import {
  ContentPublicCategoriesType,
  ContentPublicCategoryType,
  ContentPublicCategoryFilterPayloadType,
  ContentPublicDetailsType,
  CategoryItemsType,
} from './types/contentPublicTypes';

export const getContentPublicCategories = async (): Promise<
  ContentPublicCategoriesType[]
> => {
  const response = await httpClient.get(
    '/app/content-public/content-categories'
  );
  return response.data;
};

export const getContentPublicCategory = async (
  payload: ContentPublicCategoryFilterPayloadType
): Promise<ContentPublicCategoryType> => {
  const response = await httpClient.post(
    `/app/content-public/get-content-by-category/${payload.categoryId}?currency=${currencies.euro}`,
    payload.filterRule
  );
  return response.data;
};

export const getContentPublicDetails = async (
  slug: string,
  currency: string
): Promise<ContentPublicDetailsType> => {
  const response = await httpClient.get(
    `/app/content-public/content?slug=${slug}&currency=${currency}`
  );
  return response.data;
};

export const getAboutUsContent =
  async (): Promise<ContentPublicDetailsType> => {
    const response = await httpClient.get(
      '/app/content-public/about-us-content'
    );
    return response.data;
  };

export const getSecurityAndQualityContent =
  async (): Promise<ContentPublicDetailsType> => {
    const response = await httpClient.get(
      '/app/content-public/security-and-quality-content'
    );
    return response.data;
  };

export const getPopularDestinationsContent =
  async (): Promise<CategoryItemsType> => {
    const response = await httpClient.get(
      `/app/content-public/popular-segments?currency=${currencies.euro}`
    );
    return response.data;
  };

export const ContentPublicQueryKeys = {
  CONTENT_PUBLIC_CATEGORIES: 'CONTENT_PUBLIC_CATEGORIES',
  CONTENT_PUBLIC_CATEGORY: 'CONTENT_PUBLIC_CATEGORY',
  // todo: maybe this keys will be nedded in the future
  // CONTENT_PUBLIC_DETAILS: 'CONTENT_PUBLIC_DETAILS',
  POPULAR_DESTINATIONS: 'POPULAR_DESTINATIONS',
};
