import { createLocalizedPathnamesNavigation } from 'next-intl/navigation';
import { localePrefix, pathnames } from '../locales/config';
import { availableLocales } from '@/availableLocales';

export const { Link, redirect, usePathname, useRouter } =
  createLocalizedPathnamesNavigation({
    locales: availableLocales.map((locale) => locale),
    pathnames,
    localePrefix,
  });
